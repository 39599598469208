<template lang="pug">
	div(id="app" @click="initTick()")
		div(id="timer" v-if="running")
			span(id="minutes") {{ minutesLeft }}
			|&nbsp;:&nbsp;
			span(id="seconds") {{ secondsLeft }}
		div(v-else id="start-indicator") Click to Start
</template>

<style>
#app {
	width: 100%;
	background: #ccc;
}

#app #timer {
	text-align: center;
	margin-top: 100px;
	font-size: 10em;
	font-weight: bold;
	color: red;
}

#app #start-indicator {
	text-align: center;
	margin-top: 100px;
	font-size: 10em;
	font-weight: bold;
	color: red;
}
</style>


<script>
export default {
	data: function() {
		return {
			running: false,
			intervalMinutes: 5,
			baseline: new Date().getTime(),
			secondsLeftToHorizon: 0,
		}
	},
	computed: {
		minutesLeft() {
			let val = Math.floor(this.secondsLeftToHorizon / 60).toString();
			val = (val.length < 2) ? '0'+val : val;
			return val;
		},
		secondsLeft() {
			let val = (this.secondsLeftToHorizon % 60).toString();
			val = (val.length < 2) ? '0'+val : val;
			return val;
		},
	},
	mounted() {
	},
	methods: {
		initTick() {
			if(!this.running) {
				this.baseline = new Date().getTime();
				this.running = true;
				this.tick();
			}
		},
		tick() {
			window.setTimeout(() => {
				this.calculateSecondsLeftToHorizon();
				this.tick();
			}, 1000);
		},
		playSound() {
			let audio = new Audio('/msg.wav');
			audio.play();
		},
		calculateSecondsLeftToHorizon() {
			let baseLineTs = Math.floor(this.baseline/1000);
			let now = Math.floor(new Date().getTime()/1000);
			let horizon = baseLineTs + this.intervalMinutes * 60;

			this.secondsLeftToHorizon = horizon - now;

			if(this.secondsLeftToHorizon <= 0) {
				this.baseline = new Date().getTime();
				this.playSound();
			}
		}
	}
}
</script>